<template>
  <div class="dialog_block">
    <el-dialog
      :title="title"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
      @close="isClose"
      :visible.sync="show"
    >
      <el-form
        ref="comForm"
        :model="form"
        style="padding: 0 0.5rem"
        label-width="1.2rem"
      >
        <template v-for="item in list">
          <el-form-item
            :key="item.prop"
            :label="item.label"
            :prop="item.prop || ''"
            :required="item.required || false"
            :rules="item.rules || null"
            :size="item.size || ''"
          >
              <img
                v-if="form[item.type] === 'img'"
                :src="form[item.prop]"
                class="avatar"
              />
            <span v-if="form[item.type] !== 'img'">{{ form[item.prop] }}</span>
          </el-form-item>
        </template>
      </el-form>
      <div style="text-align:center">
        <el-button @click="isClose">取消</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: 'tablePage',
  components: {},
  props: {
    title: {
      type: String,
      default: '',
    },
    // 表单配置
    list: {
      type: Array,
      default: () => {
        return []
      },
    },
    // 初始表单数据
    params: {
      type: Object,
      default: () => {
        return {}
      },
    },
    // edit是否是编辑状态
    edit: {
      type: String,
      default: 'add',
    },
  },
  watch: {
    params: {
      immediate: true,
      handler: function(val) {
        this.form = val
      },
    }
  },
  data() {
    return {
      form: {},
      show: false,
      column: [],
    }
  },
  created() {},
  mounted() {
  },
  methods: {
    handleAvatarSuccess(res, file) {
      console.log(res, file)
      this.form.photo = URL.createObjectURL(file.raw)
    },
    beforeAvatarUpload(file) {
      const isJPG = file.type === 'image/jpeg' || file.type === 'image/png'
      const isLt2M = file.size / 1024 / 1024 < 2

      if (!isJPG) {
        this.$notice({
          message: '上传头像图片只能是 JPG/PNG 格式!'
        }).isShow()
      }
      if (!isLt2M) {
        this.$notice({
          message: '上传头像图片大小不能超过 2MB!'
        }).isShow()
      }
      return isJPG && isLt2M
    },
    submitForm() {
      const _val = 'comForm'
      const self = this
      // eslint-disable-next-line consistent-return
      return self.$refs[_val].validate((valid) => {
        if (valid) {
          this.$emit('onSubmit', self.form)
        } else {
          console.log('error submit!!')
          return false
        }
      })
    },
    isOpen() {
      this.show = true
    },
    isClose() {
      this.show = false
      this.$emit('close', '')
    },
  },
}
</script>

<style lang="scss" scoped></style>
<style lang="scss">
.dialog_block {
  .el-dialog {
    margin-top: 10vh !important;
  }
  .el-form-item {
    margin-bottom: 22px;
  }
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409eff;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 178px;
    height: 178px;
    line-height: 178px;
    text-align: center;
  }
  .avatar {
    width: 178px;
    height: 178px;
    display: block;
  }
}
</style>
