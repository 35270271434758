<template>
  <div class="graduation_student_list_page">
    <myTable title="缴费记录" :list="list" :columns="columns">
      <template slot="btns" slot-scope="item">
        <div class="table_btns">
          <div class="table_btn" @click="handleView(item.data.row)">查看</div>
        </div>
      </template>
    </myTable>
    <comViewDialog
      :list="fromList"
      ref="comdialog"
      :params.sync="stuInfo"
      title="查看"
    ></comViewDialog>
    <comPagination :params="params" @changePage="changePage"></comPagination>
  </div>
</template>

<script>
import myTable from "@/components/admin/table.vue";
import comViewDialog from "@/components/admin/viewDialog.vue";
import comPagination from "@/components/admin/pagination.vue";
export default {
  name: "graduationStudentList",
  components: {
    comPagination,
    comViewDialog,
    myTable,
  },
  data() {
    return {
      columns: [
        {
          prop: "orderid",
          label: "订单号",
        },
        {
          prop: "semesterIds",
          label: "学期",
        },
        {
          prop: "number",
          label: "学员人数",
        },
        {
          prop: "price",
          label: "费用/元",
        },
        {
          prop: "paytime",
          label: "购买日期",
        },
        {
          prop: "endtime",
          label: "到期日期",
        },
      ],
      list: [],
      fromList: [
        {
          prop: "orderid",
          label: "订单号",
        },
        {
          prop: "number",
          label: "学员人数",
        },
        {
          prop: "semesterIds",
          label: "学期",
        },
        {
          prop: "price",
          label: "费用",
        },
        {
          prop: "endtime",
          label: "到期日期",
        },
      ],
      stuInfo: {
        orderid: "",
        number: "",
        semesterIds: "",
        price: "",
        endtime: "",
      },
      //分页
      params: {
        page: 0,
        size: 10,
        total: 0,
      },
    };
  },
  created() {},
  mounted() {
    this.init();
  },
  methods: {
    //初始化
    init() {
      this.getInfo();
    },
    getInfo() {
      let data = {
        schoolId: this.$store.state.userInfo.schoolId,
      };
      this.list = [];
      this.api.admin.findOrderList(this.params, data).then((res) => {
        this.list = res.data.list.map((item) => {
          let obj = {
            ...item.costOrder,
            endtime: item.endtime,
          };
          obj.semesterIds = obj.semesterIds + "学期";
          obj.paytime = this.common.dateFormat(obj.paytime * 1000);
          obj.endtime = this.common.dateFormat(obj.endtime);
          return obj;
        });
        this.params.total = res.data.total;
        console.log(this.list);
      });
    },
    handleView(val) {
      this.$refs.comdialog.isOpen();
      this.stuInfo = {
        orderid: val.orderid,
        number: val.number,
        semesterIds: val.semesterIds,
        price: val.price,
        endtime: val.endtime,
      };
    },
    //分页 todo 待测试
    changePage(val) {
      this.params.page = val;
      this.getInfo();
    },
  },
};
</script>

<style lang="scss">
.graduation_student_list_page {
  .code_block {
    @include flex(row, flex-end, center);

    .el-input__inner {
      height: 60px;
      width: 250px;
      font-size: 20px;
    }
  }
}
</style>